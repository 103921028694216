<template lang="pug">
  .settings-page
    TheNavBar.the-nav-bar
    .settings
      h1.settings__title Settings:
      section.settings-section
        h2.settings-section__title General
        .settings-section__body
          AppSelectInput( label="Frequency:" :value="emailFrequency" @change="setUserEmailFrequency($event.target.value)" )
            option( value=0) Never
            option( value=1 ) Weekly
            option( value=2 ) Monthly
      section.settings-section
        h2.settings-section__title Account
        .settings-section__body
          a.settings-action( @click="changeEmailCurrentUser" ) Change email
          //- AppInput( label="Change email" )
          a.settings-action( @click="downloadFeedData") Download my feed data
          a.settings-action( @click="deleteCurrentUser" style="color: red;" ) Delete Account
</template>

<script>
import isEmail from 'validator/lib/isEmail'
import { saveAs } from 'file-saver'

import { db, auth } from '@/firebase.js'

import TheNavBar from '@/components/TheNavBar.vue'
import AppButton from '@/components/AppButton.vue'
import AppInput from '@/components/AppInput.vue'
import AppSelectInput from '@/components/AppSelectInput.vue'

export default {
  name: 'settings',
  components: {
    TheNavBar,
    AppButton,
    AppInput,
    AppSelectInput
  },
  data () {
    return {
      user: {},
      userData: {},
      newEmail: ''
    }
  },
  created () {
    auth.onAuthStateChanged(user => {
      if (user) {
        this.user = user
        this.setUserDataChangeListener() // setup listener for feed data
        return
      }
      this.$router.push({ name: 'home' })
    })
  },
  computed: {
    emailFrequency () {
      return this.userData && this.userData.settings && this.userData.settings.frequency
    }
  },
  methods: {
    setUserDataChangeListener () {
      if (!this.user.uid) return alert('not logged in')
      return db
        .collection('users')
        .doc(this.user.uid)
        .onSnapshot(doc => {
          this.userData = doc.data()
        })
    },
    deleteCurrentUser () {
      if (prompt('This will delete your account and all your data, are you sure? Type "yes" to confirm.') !== 'yes') return
      return auth.currentUser
        .delete()
        .then(() => {
          this.$router.push({ name: 'home' })
        }).catch((err) => {
          alert(err.message)
        })
    },
    changeEmailCurrentUser () {
      const newEmail = prompt('New email adress:', this.user.email)
      if (!newEmail) return
      if (!isEmail(newEmail)) return alert('Not a valid email adress')
      return auth.currentUser
        .updateEmail(newEmail)
        .then(() => {
          // Update successful.
        }).catch(err => {
          alert(err.message)
        })
    },
    setUserEmailFrequency (newFreq) {
      if (!this.user.uid) return alert('not logged in')
      return db
        .collection('users')
        .doc(this.user.uid)
        .set({
          settings: {
            frequency: Number(newFreq)
          }
        }, { merge: true })
    },
    downloadFeedData () {
      return db
        .collection('users')
        .doc(this.user.uid)
        .collection('feeds')
        .get()
        .then(collection => {
          // get all feeds
          const feeds = collection.docs.map(doc => ({ id: doc.id, ...doc.data() }))
          // construct blob with JSON content
          var blob = new Blob([JSON.stringify(feeds, null, 2)], { type: 'text/plain;charset=utf-8' })
          // download the file
          return saveAs(blob, 'my-feeds.json')
        })
    },
    logout () {
      return auth.signOut()
        .then(() => {
          this.$router.push({ name: 'home' })
        })
        .catch(err => {
          alert(err.message)
          console.error(err)
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.settings-page {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 60px 140px;

  @media (max-width: 600px) {
    padding: 60px 20px;
  }
}

.the-nav-bar {
  position: absolute;
  top: 30px;
  right: 40px;
  color: white;
  font-weight: bold;

  @media (max-width: 600px) {
    right: 20px;
  }
}

.settings {
  text-align: left;
  margin: 20px 0;

  &__title {
    font-size: 2.5rem;
    margin: .3em 0;
    margin-bottom: .8em;
  }
}

.settings-section {
  &__title {
    font-size: 1.4rem;
    margin: 0.5em 0;
  }
  &__body {
    padding-left: 10px;
  }
}

.settings-action {
  display: block;
  cursor: pointer;
  margin: 1.2em 0;

  &:hover {
    text-decoration: underline;
  }
}
</style>
